// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Inject, Injectable, Optional } from '@angular/core';
import { Library, Loader } from '@googlemaps/js-api-loader';
import { environment } from '@environment';
import { GOOGLE_MAPS_LIBRARIES_CONFIG } from '../google-maps-libraries.config';
import { TranslationService } from '../../translation/translation.service';
@Injectable({
  providedIn: 'root',
})
// This service instances different objects from Google Maps, and makes sure the API is loaded
// This allows to do not have direct dependencies of Google Maps API in other services to be able unit test them
export class GoogleMapsService {
  private readonly API_KEY = environment.googleMapsApiKey;
  private readonly GOOGLE_API_LINK = 'https://www.google.com/maps/search/?api=1&query=';
  private readonly DEFAULT_LIBRARIES: Library[] = ['places'];
  google: typeof google | null = null;

  constructor(
    @Optional() @Inject(GOOGLE_MAPS_LIBRARIES_CONFIG) private readonly libraries: Library[],
    private translationService: TranslationService,
  ) {
    this.libraries = this.libraries ?? this.DEFAULT_LIBRARIES;
  }

  async getMapTypeId(): Promise<string> {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    return this.google.maps.MapTypeId.ROADMAP;
  }

  createMapsUrlQuery(address: string): string {
    return encodeURI(`${this.GOOGLE_API_LINK}${address}`);
  }

  async getMap(mapElement: HTMLElement, mapOptions: google.maps.MapOptions): Promise<google.maps.Map> {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    const map = new this.google.maps.Map(mapElement, mapOptions);
    await this.waitForProjection(map);
    return map;
  }

  async getLatLng(lat: number, lng: number): Promise<google.maps.LatLng> {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    return new this.google.maps.LatLng(lat, lng);
  }

  async getPoint(x: number, y: number): Promise<google.maps.Point> {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    return new google.maps.Point(x, y);
  }

  async getSize(width: number, height: number): Promise<google.maps.Size> {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    return new google.maps.Size(width, height);
  }

  async addMarker(markerOptions: google.maps.MarkerOptions): Promise<google.maps.Marker> {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    return new google.maps.Marker(markerOptions);
  }

  async getLatLngBounds(): Promise<google.maps.LatLngBounds> {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    return new google.maps.LatLngBounds();
  }

  async addAutocomplete(inputElement: HTMLInputElement) {
    if (!this.google) {
      this.google = await this.loadGoogle();
    }
    return new google.maps.places.Autocomplete(inputElement);
  }

  waitForProjection(map: google.maps.Map): Promise<void> {
    return new Promise<void>(resolve => {
      google.maps.event.addListenerOnce(map, 'projection_changed', () => {
        resolve();
      });
    });
  }

  private async loadGoogle(): Promise<typeof google> {
    const language = this.translationService.getActiveLanguage();
    const loader = new Loader({ apiKey: this.API_KEY, libraries: this.libraries, language });
    return await loader.load();
  }

  // x, y from the top left - use scale to have real pixel distance
  distanceToTopLeftInPx(latLng: google.maps.LatLng, map: google.maps.Map) {
    const scale = Math.pow(2, map.getZoom());
    const NorthWest = new google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const pointNorthWest = map.getProjection().fromLatLngToPoint(NorthWest);
    const pointLatLong = map.getProjection().fromLatLngToPoint(latLng);
    return new google.maps.Point(
      Math.floor((pointLatLong.x - pointNorthWest.x) * scale),
      Math.floor((pointLatLong.y - pointNorthWest.y) * scale),
    );
  }

  getCurrentMapRadius(map: google.maps.Map): number | null {
    const bounds = map.getBounds();
    if (!bounds) return null;
    const center = bounds.getCenter();

    const northSouthRadius = google.maps.geometry.spherical.computeDistanceBetween(
      center,
      new google.maps.LatLng(bounds.getNorthEast().lat(), center.lng()),
    );
    const eastWestRadius = google.maps.geometry.spherical.computeDistanceBetween(
      center,
      new google.maps.LatLng(center.lat(), bounds.getNorthEast().lng()),
    );
    const radius = northSouthRadius <= eastWestRadius ? northSouthRadius : eastWestRadius;

    return Number(radius.toFixed(0));
  }

  getCircle(options: google.maps.CircleOptions): google.maps.Circle {
    return new google.maps.Circle(options);
  }

  clearAllMapEvents(googleMapInstance: google.maps.Map) {
    google.maps.event.clearListeners(googleMapInstance);
  }
}
