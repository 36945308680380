import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { FlightExperience } from '../models/flight-experience.model';
import { FlightBE, FlightExperienceBE } from '../models/flight-experience-be.model';
import { FlightsView } from '../models/flights-view.model';
import { RoundFlightWithAlternativesIds } from '../models/round-flight-with-alternatives.model';
import { RoundFlight } from '../models/round-flight.model';
import { FlightLeg } from '../models/flight-leg.model';
import { ApiResult } from '../../models/api-result.model';
import { NOTIFICATIONS } from '../utils/notifications';
import { ExperienceUtilsService } from '../../experience/utils/experience-utils.service';
import { UtilsService } from '../../utils/utils.service';
import { ONE_DAY_IN_MS } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class FlightsApiService {
  private readonly ENGINE_API = `${environment.apiEnv}api/private/`;
  private readonly TRAVEL_API = `${environment.apiEnv}api/v2/`;

  constructor(
    private httpClient: HttpClient,
    private experienceUtils: ExperienceUtilsService,
    private utilsService: UtilsService,
  ) {}

  public getFlightsView$(
    idOrHash: string | number,
    flightExperienceId: number,
  ): Observable<ApiResult<FlightsView | null>> {
    return this.getFlightExperienceBE$(idOrHash, flightExperienceId).pipe(
      switchMap(flightExperienceBE => {
        if (flightExperienceBE.is_alternative_for) {
          return of({
            payload: null,
            message: NOTIFICATIONS.Flights.Api.Alternative,
            success: false,
          });
        }
        return this.getMainRoundFlightWithAlternativesIds$(idOrHash, flightExperienceBE).pipe(
          switchMap(mainRoundFlightWithAlternativesIds => {
            const { inboundAlternativesIds, outboundAlternativesIds, ...mainRoundFlight } =
              mainRoundFlightWithAlternativesIds;

            const outboundAlternativesBE$: Observable<FlightExperienceBE[]> = this.getAlternativeFlightsBE$(
              outboundAlternativesIds,
              idOrHash,
            );
            const inboundAlternativesBE$: Observable<FlightExperienceBE[]> = this.getAlternativeFlightsBE$(
              inboundAlternativesIds,
              idOrHash,
            );

            return forkJoin([of(mainRoundFlight), outboundAlternativesBE$, inboundAlternativesBE$]);
          }),
          map(
            ([mainRoundFlight, outboundAlternativesBE, inboundAlternativesBE]: [
              mainRoundFlight: RoundFlight,
              outboundAlternativesBE: FlightExperienceBE[],
              inboundAlternativesBE: FlightExperienceBE[],
            ]) => {
              return {
                payload: {
                  main: mainRoundFlight,
                  alternatives: this.sortByStatus(
                    this.matchAlternativeRoundFlights(outboundAlternativesBE, inboundAlternativesBE),
                  ),
                },
                message: '',
                success: true,
              };
            },
          ),
        );
      }),
    );
  }

  private getFlightExperienceBE$(
    idOrHash: string | number,
    flightExperienceId: number,
  ): Observable<FlightExperienceBE> {
    const url = this.isId(idOrHash)
      ? `${this.ENGINE_API}bookings/${idOrHash}/experiences/${flightExperienceId}/flight/`
      : `${this.TRAVEL_API}bookings/${idOrHash}/experiences/${flightExperienceId}`;
    return this.httpClient.get<FlightExperienceBE>(url);
  }

  private isId(idOrHash: string | number): boolean {
    return Number.isInteger(idOrHash);
  }

  private getAlternativeFlightsBE$(
    alternativeIds: number[],
    idOrHash: string | number,
  ): Observable<FlightExperienceBE[]> {
    return alternativeIds.length > 0
      ? forkJoin(alternativeIds.map(id => this.getFlightExperienceBE$(idOrHash, id)))
      : of([]);
  }

  private transformIntoFlightExperience(flightExperienceBE: FlightExperienceBE): FlightExperience {
    return {
      id: flightExperienceBE.id,
      flightLegs: flightExperienceBE.flights.map(flightBE =>
        this.transformIntoFlightLeg(flightBE, flightExperienceBE.travelers),
      ),
      parentId: flightExperienceBE.is_alternative_for,
      status: flightExperienceBE.status,
      title: flightExperienceBE.title,
      duration: this.transformDuration(flightExperienceBE.duration),
      dayDifference: this.calculateDayDifference(flightExperienceBE.flights),
    };
  }

  private transformIntoFlightLeg(flightBE: FlightBE, travelers: string[]): FlightLeg {
    return {
      airlineName: flightBE.carrier_airline?.title ?? null,
      arrival: {
        airport: {
          name: flightBE.arrival_airport.title,
          iata: flightBE.arrival_airport.iata,
        },
        datetime: flightBE.arrival_datetime,
        terminal: flightBE.arrival_terminal,
      },
      cabin: flightBE.cabin,
      departure: {
        airport: {
          name: flightBE.departure_airport.title,
          iata: flightBE.departure_airport.iata,
        },
        datetime: flightBE.departure_datetime,
        terminal: flightBE.departure_terminal,
      },
      layoverDuration: flightBE.layover,
      number: flightBE.carrier_flight_number,
      flightDuration: flightBE.duration,
      travelers: travelers,
    };
  }

  private getMainRoundFlightWithAlternativesIds$(
    idOrHash: string | number,
    flightExperienceBE: FlightExperienceBE,
  ): Observable<RoundFlightWithAlternativesIds> {
    if (flightExperienceBE.return_experience) {
      // is outbound
      return this.getFlightExperienceBE$(idOrHash, flightExperienceBE.return_experience).pipe(
        map(inboundBE => ({
          inbound: this.transformIntoFlightExperience(inboundBE),
          inboundAlternativesIds: inboundBE.alternatives,
          localPriceWithCurrency: flightExperienceBE.total_price,
          outbound: this.transformIntoFlightExperience(flightExperienceBE),
          outboundAlternativesIds: flightExperienceBE.alternatives,
          priceWithCurrency: flightExperienceBE.total_price_booking_currency,
        })),
      );
    } else if (flightExperienceBE.outbound_experience) {
      // is inbound
      return this.getFlightExperienceBE$(idOrHash, flightExperienceBE.outbound_experience).pipe(
        map(outboundBE => ({
          inbound: this.transformIntoFlightExperience(flightExperienceBE),
          inboundAlternativesIds: flightExperienceBE.alternatives,
          localPriceWithCurrency: outboundBE.total_price,
          outbound: this.transformIntoFlightExperience(outboundBE),
          outboundAlternativesIds: outboundBE.alternatives,
          priceWithCurrency: outboundBE.total_price_booking_currency,
        })),
      );
    } else {
      // is one way
      return of({
        inbound: null,
        inboundAlternativesIds: [],
        localPriceWithCurrency: flightExperienceBE.total_price,
        outbound: this.transformIntoFlightExperience(flightExperienceBE),
        outboundAlternativesIds: flightExperienceBE.alternatives,
        priceWithCurrency: flightExperienceBE.total_price_booking_currency,
      });
    }
  }

  private matchAlternativeRoundFlights(
    outboundAlternativesBE: FlightExperienceBE[],
    inboundAlternativesBE: FlightExperienceBE[],
  ): RoundFlight[] {
    // Alternatives of one flight only can be linked with alternatives of the main's linked flight
    const outboundRoundFlights = outboundAlternativesBE.map(outboundAlternativeBE => {
      const inboundAlternativeBE = inboundAlternativesBE.find(
        inboundAlternativeBE => inboundAlternativeBE.outbound_experience === outboundAlternativeBE.id,
      );
      return {
        inbound: inboundAlternativeBE ? this.transformIntoFlightExperience(inboundAlternativeBE) : null,
        localPriceWithCurrency: outboundAlternativeBE.total_price,
        outbound: this.transformIntoFlightExperience(outboundAlternativeBE),
        priceWithCurrency: outboundAlternativeBE.total_price_booking_currency,
      };
    });
    // Assuming links already done before
    const inboundOnlyRoundFlights = inboundAlternativesBE
      .filter(inboundAlternativeBE => inboundAlternativeBE.outbound_experience === null)
      .map(inboundAlternativeBE => ({
        inbound: null,
        localPriceWithCurrency: inboundAlternativeBE.total_price,
        outbound: this.transformIntoFlightExperience(inboundAlternativeBE),
        priceWithCurrency: inboundAlternativeBE.total_price_booking_currency,
      }));

    return [...outboundRoundFlights, ...inboundOnlyRoundFlights];
  }

  private transformDuration(durationBE: string | null): string | null {
    if (!durationBE) {
      return null;
    }

    return this.utilsService.getFormattedDuration(durationBE, 'acc');
  }

  private calculateDayDifference(flightsBE: FlightBE[]): number {
    let dayDifference = 0;

    if (flightsBE.length) {
      const departureDate = new Date(flightsBE[0].departure_datetime);
      const arrivalDate = new Date(flightsBE[flightsBE.length - 1].arrival_datetime);

      departureDate.setHours(0, 0, 0, 0);
      arrivalDate.setHours(0, 0, 0, 0);

      dayDifference = Math.round((arrivalDate.getTime() - departureDate.getTime()) / ONE_DAY_IN_MS);
    }

    return dayDifference;
  }

  private sortByStatus(roundFlights: RoundFlight[]): RoundFlight[] {
    return [...roundFlights].sort((flight1, flight2) =>
      this.experienceUtils.sortByStatus(flight1.outbound.status, flight2.outbound.status),
    );
  }
}
