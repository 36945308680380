export const NOTIFICATIONS = {
  AlternativeDays: {
    UnexpectedTripTypology: 'Alternative days are not available for the current trip type',
  },
  AttachedDocuments: {
    NoFile: 'No attached file',
    NoData: 'Missing experience id or current attached files',
    TooBig: 'The attached document is too big',
  },
  RecalculateMatching: {
    Failure: 'The process of matching recalculation has failed. Please, try again in a few minutes',
    Timeout: 'Recalculate matching took too long to finish. Please, try again in a few minutes',
    Success:
      'The process of matching recalculation has finished. In order to see the new results, please refresh the page by clicking “Refresh”',
  },
  DownloadPDF: {
    Failure: 'An error occurred generating the PDF, please try again later',
    Timeout: 'This PDF took too long to download, please try again later',
    Success: 'Your PDF is ready to download',
  },
  GetAllRates: {
    NoContent: 'The experiences in this day are not connected to a provider or already have services',
  },
  GetParams: {
    Error: 'Could not get the expected url parameter',
  },
  GetRates: {
    Failure: 'No rates could be found',
    NoContent: 'No rates could be found',
    Success: 'Rates obtained successfully',
    Unexpected: 'Bad response getting rates',
  },
  ConfirmBookingExperience: {
    Failure: 'Rates are not available anymore or expired',
    NoContent: 'Rates are not available anymore or expired',
    Success: 'Reservation has been confirmed',
    Unexpected: 'Bad response confirming booking',
  },
  DragAndDrop: {
    WithAlternatives: 'You can not convert an experience that already has alternatives into an alternative',
  },
  DuplicateTrip: {
    Success: 'Trip cloned!',
    Unexpected: 'Bad response duplicating trip',
  },
  MoveExperienceInCalendar: {
    ServicesWarning: 'The availability of the services of this experience will be wrong when changing the day',
  },
  Login: {
    InvalidCredentials: 'Invalid email or password',
  },
  NewExperience: {
    Categories: 'There were problems getting categories from the server',
  },
  GetConfirmBookingExperienceData: {
    NotFound: 'The url is not valid or the experience has been deleted',
    CanNotBookRates: 'The experience is not ready for booking or already booked',
  },
  GetCancelBookingExperienceData: {
    NotFound: 'The url is not valid or the experience has been deleted or already canceled',
  },
  MapDialog: {
    NotFound: "The map can't be shown cause this day doesn't have experiences with valid locations",
  },
  AwardWallet: {
    SingleExperienceImport: 'The experience has been imported successfully.',
    MultipleExperienceImport: 'The following experiences have been imported successfully:',
    NoExperienceImport: 'There was some problems and experiences could not be imported.',
  },
  GetReservationChannelsData: {
    NotFound: 'The url is not valid or the experience has been deleted',
    NoAvailabilityChannels: 'The experience does not have availability channels',
  },
  ImportFromGooglePlaces: {
    Success:
      'Booking Experience correctly created. PLEASE ADJUST CITY, CATEGORY & SUBCATEGORY and review the imported data.',
  },
  RequestPayment: {
    Success: 'Payment already requested to Ess Finance',
  },
  Errors: {
    Default: 'Something went wrong.',
    Unauthorized: 'Sorry, your token expired. Please, sign in again.',
    Forbidden: `You don't have permission to access the data or perform this action.`,
  },
  Flights: {
    AlternativeFlightURL: 'The URL is for an alternative flight, please access through the main one',
  },
  Unexpected: {
    Store: {
      AlternativeDays: "Alternative days or target alternative day don't exist in the store",
      BookingExperiences: "Booking experiences list or the target booking experience don't exist in the store",
      Curation: "Filter doesn't exist in the store",
      Days: "Days or target day don't exist in the store",
      FlightsBookingExperience: "Flight outbound doesn't have a corresponding booking experience",
      FlightsMain: 'There is no main flight',
      Itinerary: "Days or booking experience list don't exist in the store",
      OriginalExperiences: "Original Experience List or the target Original Experience don't exist in the store",
      Services: 'Expected service is not in the store',
      Trip: "Trip doesn't exist in the store",
    },
    Calendar: {
      DayId: "Target day doesn't have an id",
    },
    Functions: {
      PositionOutOfBounds: 'Given position is out of bounds',
      ItemNotFoundOnDelete: "Item with the given search property and property value wasn't found when deleting",
      ItemNotFoundOnPatch: "Item with the given search property and property value wasn't found when patching",
      ItemNotFoundOnReplace: "Item with the given search property and property value wasn't found when replacing",
    },
  },
  RequestAvailability: {
    Success: 'Your email request for availability has been successfully sent to the provider.',
    Failure:
      "An unexpected error occurred and your email request for availability wasn't sent. Please try again later, and if the issue continues, contact the tech team.",
  },
  RequestAvailabilities: {
    Success: 'Your email requests for availabilities have been successfully sent to the provider.',
    Failure:
      "An unexpected error occurred and your email requests for availabilities weren't sent. Please try again later, and if the issue continues, contact the tech team.",
  },
  ConfirmTrip: {
    Success: 'Reservation request has been sent to the Booking Desk team',
  },
};
